/* global $j */

// Auto update prices with pricebreaks
function watchPriceBreaks(mySelector = '') {
    // checks if a price should have superscript cents
    // and return the adjusted price if it should
    function superPrice(originalPrice, newPrice) {
      const shouldSuper = originalPrice.indexOf('<sup>') > -1;
      let newPriceArr;
      if(shouldSuper) {
        newPriceArr = newPrice.split('.');
        return `${newPriceArr[0]}<sup>. + ${newPriceArr[1]} </sup>`;
      }
      return newPrice;
    }
  
    // sets the innerHTML to the appropriate price
    function updatePrice(breaks, qtyField) {
      // this code is inside a settimeout, because in IE8, this event fires before teh field value has changed
      const priceBox = this;
      setTimeout(function(){
        const qty = qtyField.value;
        let uomHTML, uomSpan;
        for(let price in breaks) {
          if(qty >= breaks[price][0] && qty <= breaks[price][1]) {
            uomSpan = priceBox.querySelector('span.price_uom');
            uomHTML = uomSpan ? uomSpan.outerHTML : '';
            priceBox.innerHTML = superPrice(priceBox.innerHTML, price) + uomHTML;
            break;
          }
        }
      },0);
    }

    
  // if the qty field already has an update function then we remove it
  function removeWatchers(qtyField, elem) {
    if(qtyField.updateFunction) {
      qtyField.removeEventListener('change', qtyField.updateFunction);
      qtyField.removeEventListener('keyup', qtyField.updateFunction);
      elem.find('button.decreaser, button.increaser').each(function(){this.removeEventListener('click', qtyField.updateFunction)});
    }
  }

  // create a new update function, remember it and add it
  function addWatchers(breaksJson, qtyField, pbWrapper, target) {
    const breaks = JSON.parse(breaksJson.replace(/'/g, '"'));
    qtyField.updateFunction = updatePrice.bind(target, breaks, qtyField);
    qtyField.addEventListener('change', qtyField.updateFunction);
    qtyField.addEventListener('keyup', qtyField.updateFunction);
    pbWrapper.find('button.decreaser, button.increaser').each(function(){this.addEventListener('click', qtyField.updateFunction)});
  }

  // Grabs all the price break wrappers and sets/resets any watchers on the quantity field so that the price
  // is updated when quantity changes
  // Because of variants, this may be run more than once, in which case it should clear the old watchers
  $j(mySelector + ' .pricebreaks_wrapper').each(function(){
    const pbWrapper = $j(this);
    const breaksJson = this.getAttribute('data-pricebreaks');
    const target = pbWrapper.find('.price_text, .results_price')[0];
    const qtyField = pbWrapper.find('input[name$=quantity]')[0];
    if(target && qtyField){
      removeWatchers(qtyField, pbWrapper);
      if(breaksJson){
        addWatchers(breaksJson, qtyField, pbWrapper, target);
        qtyField.updateFunction();
      }
    }
    return null;
  });
}

export {watchPriceBreaks};
