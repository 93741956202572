export const replace_svg = () => {
  const svgasimg = () => {
    return document.implementation.hasFeature(
      'http://www.w3.org/TR/SVG11/feature#Image',
      '1.1'
    );
  }
  var images = [];
  var i, img_count, test_img, src, fallback;
  if (!svgasimg()) {
    images = document.getElementsByTagName('img');
    if (!images.length) {
      images = document.getElementsByTagName('IMG');
    }
    img_count = images.length;

    for (i = 0; i < img_count; i++) {
      test_img = images[i];
      src = test_img.getAttribute('src');

      if (src.match(/svgz?$/)) {
        /* URL ends in svg or svgz */
        fallback = test_img.getAttribute('data-fallback');
        if (fallback) {
          test_img.setAttribute('src', fallback);
        }
      }
    }
  }
}
// Function to record page response times
// to record ajax times you must send a field called ajax_request_time with the Ajax request.
// You can then use the MetaLocation::Timing to populate this into the omniture ttml of the response
export const s_addPageLoadEvent = (eventsExist, ajaxRequestTime) => {
  var timing = 0,
    evt = '';
  // return unless chrome or firefox
  if (
    navigator.userAgent.indexOf('Firefox') === -1 && navigator.userAgent.indexOf('Chrome') === -1
  ) { return evt; }
  // return if navigation was back, forward, reload, etc
  if (typeof performance === 'undefined' || performance.navigation.type !== 0){ return evt; }
  if (!window.nx.ute.s_capturedInitialLoad) {
    timing =
      (window.performance &&
        window.performance.timing &&
        window.performance.timing.domLoading - window.performance.timing.requestStart) ||
      0;
  } else if (ajaxRequestTime !== 0) {
    timing = new Date() - ajaxRequestTime;
  }
  if (timing > 0) {
    if (eventsExist) evt = ',';
    evt += 'event71=' + timing + ',event72';
  }
  window.nx.ute.s_capturedInitialLoad = true;
  return evt;
}

export const doAdobeWithVars = (payload = {}, linkName = '') => {
  if (typeof s === 'undefined') { return; }
  const linkTrackVarsArr = [];
  if (payload.events) {
    window.s.events = payload.events;
    // turn "ev1=1,ev2,ev3" into 'ev1,ev2,ev3' for linkTrackEvents
    window.s.linkTrackEvents = payload.events.split(',').reduce((acc, cur) => {
      return [...acc, cur.split('=')[0]];
    }, []).join(',');
    linkTrackVarsArr.push('events');
  }
  Object.keys(payload).forEach(el => {
    if (el !== 'events') {
      window.s[el] = payload[el];
      linkTrackVarsArr.push(el);
    }
  });
  window.s.linkTrackVars = linkTrackVarsArr.join(',');
  window.s.tl(true, 'o', linkName);
}

export const doAdobeEvent = (evt, description) => {
  var oldLTV = window.s.linkTrackVars;
  var oldLTE = window.s.linkTrackEvents;
  var oldE = window.s.events;
  window.s.linkTrackVars = 'events';
  window.s.linkTrackEvents = evt;
  window.s.events = evt;
  window.s.tl(true, 'o', description ? description : 'link event');
  window.s.linkTrackVars = oldLTV;
  window.s.linkTrackEvents = oldLTE;
  window.s.events = oldE;
}

export const trackCataloguePage = (catalogueName, page) => {
  window.s.pageName = 'catalogue:' + catalogueName + ':' + page;
  window.s.hier1 = window.s.pageName;
  window.s.events = 'event1,event150';
  window.s.channel = window.s.eVar2 = 'catalogue';
  window.s.prop2 = window.s.eVar3 = 'catalogue:' + catalogueName;
  window.s.products = '';

  window.s.t();
}

export const doAdobeLink = linkName => {
  window.s.linkTrackVars = 'None';
  window.s.linkTrackEvents = 'None';
  window.s.tl(this, 'o', linkName);
}

export const doAdobeEvent_sampled = (evt, description, sample_rate) => {
  if (Math.random() < sample_rate) {
    doAdobeEvent(evt, description);
  }
}

export const makePost = (uri, payload, successCB, failureCB, successStatus) => {
  // e.g. makeRequest('https://www.winc.com.au', 'POST', successCallbackFunction, failureCallbackFunction),
  successStatus = successStatus || 201;

  const redirectStatus = 307;
  const inactiveStatus = 401;
  let responseStatus;

  var request = fetch(uri, {
    method: 'POST',
    body: payload,
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    }
  })
  .then(res => {
    if (res.status === successStatus || res.status === redirectStatus || res.status === inactiveStatus) {
      responseStatus = res.status;
      return res.json();
    }
    throw new Error('Network response was not ok.');
  })
    .then(function (json) {
      if (json !== null) {
        if (responseStatus === redirectStatus || responseStatus === inactiveStatus) {
          failureCB(uri, json);
        } else {
          successCB(uri, json);
        }
      } else {
        failureCB(uri, null);
      }
    })
    .catch(function (err) {
      failureCB(uri, null);
    });

  return request;
};

export const updateParam = (uri, newKey, newValue) => {
  // adds or replaces a paramater value in a uri
  var newParams = [],
    i,
    hash,
    params,
    newParam,
    newParamStr;
  newValue = encodeURIComponent(newValue);
  i = uri.indexOf('#');
  hash = i === -1 ? '' : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);
  i = uri.indexOf('?');
  params = i === -1 ? '' : uri.substr(i + 1).split('&');
  uri = i === -1 ? uri : uri.substr(0, i);
  newParam = newValue === '' ? '' : newKey + '=' + newValue;
  for (i = 0; i < params.length; i++) {
    if (params[i].split('=')[0] != newKey) {
      newParams.push(params[i]);
    }
  }
  if (newValue != '') {
    newParams.push(newKey + '=' + newValue);
  }
  newParamStr = newParams.length > 0 ? '?' + newParams.join('&') : '';
  return uri + newParamStr + hash;
};

export function readDatalayer(dlName) {
  // Accepts an array and returns that argument from the
  // nxDatalayer unless it is falsey, when it returns false
  // If the node doesn't exist it return false;
  // e.g. readDatalayer(['global', 'streams', 'control_chk'])
  if (typeof nxDatalayer == 'undefined') {
    return false;
  }
  var node = nxDatalayer;
  for (var i = 0; i < dlName.length; i++) {
    if (node[dlName[i]]) {
      node = node[dlName[i]];
    } else {
      return false;
    }
  }
  return node;
}

export function getCardWidth(card) {
  return (
    card.clientWidth +
    parseInt(window.getComputedStyle(card)['margin-left'].replace('px', ''), 10)
  );
}

/********************************
 * helpers inspired by prototype.js
 *******************************/
const scriptFragment = '<script[^>]*>([\\S\\s]*?)</script\\s*>';
// remove <script> content from the string, example
// "<p>This is a test.<script>alert("Look, a test!");</script>End of test</p>".stripScripts();
// => "<p>This is a test.End of test</p>"
export const stripScripts = str =>
  str.replace(new RegExp(scriptFragment, 'img'), '');
//'a <a href="#">link</a>'.stripTags();
//  -> 'a link'
//  'a <a href="#">link</a><script>alert("hello world!");</script>'.stripTags();
// -> 'a linkalert("hello world!");'
//  'a <a href="#">link</a><script>alert("hello world!");</script>'.stripScripts().stripTags();
// -> 'a link'
export const stripTags = str =>
  str.replace(/<\w+(\s+("[^"]*"|'[^']*'|[^>])+)?(\/)?>|<\/\w+>/gi, '');
/********************************
 * ajax helpers
 *******************************/
export const evalScript = el => {
  el.querySelectorAll('script').forEach(script => {
    eval(script.innerHTML); // eslint-disable-line no-eval
  });
};

export const parseAjaxText = res => res.text();
export const parseAjaxJSON = res => res.json();
/********************************
 * DOM helpers
 *******************************/
export const identify = element => {
  if (element.id) return element.id;
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
  element.id = 'element-' + uuid;
  return element.id;
};
export const addClassName = (el, className) => {
  if (el?.classList && typeof className === 'string') {
    const classArr = className.split(' ');
    classArr.forEach(n => el.classList.add(n));
  }
};
export const removeClassName = (el, className) => {
  if (el?.classList && typeof className === 'string') {
    const classArr = className.split(' ');
    classArr.forEach(n => el.classList.remove(n));
  }
};
export const hasClassName = (el, className) =>
  typeof className === 'string' && !!el?.classList?.contains(className);
export const toggleClassName = (el, className) => {
  if (hasClassName(el, className)) {
    return removeClassName(el, className);
  } else {
    return addClassName(el, className);
  }
};
export const getElementByID = id => document.getElementById(id);
export const getFirstBySelector = selector => document.querySelector(selector);
export const getAllBySelector = selector => [
  ...document.querySelectorAll(selector)
];
export const px = pxNum => `${pxNum}px`;
export const selectAll = selector => {
  const elts = [...document.querySelectorAll(selector)];
  const isAllTrue = elts.findIndex(elt => !elt.checked) === -1;

  elts.forEach(field => {
    field.checked = !isAllTrue;
  });
};
export const unselectAll = formid => {
  const form = document.getElementById(formid);

  for (let i = 0; i < form.elements.length; i++) {
    if (form.elements[i].type == 'checkbox') {
      //further restrict by name. Checkbox needs to have the string 'del' in it.
      if (form.elements[i].name.search(/del/) >= 0) {
        form.elements[i].checked = false;
      }
    }
  }
}
export const findParentElement = (el, selector) => {
  let target = el;
  while (!target.matches(selector)) {
    target = target.parentNode;
    if (target.tagName.toLowerCase() === 'html') {
      return null;
    }
  }
  return target;
};
// e.g. styleObj = {color: 'red'}
export const setStyle = (el, styleObj) => {
  for (const prop in styleObj) {
    el.style[prop] = styleObj[prop];
  }
};
export const stopEvent = ev => {
  ev.stopPropagation();
  ev.preventDefault();
};
export const returnArg = arg => {
  return arg;
}
export function watchFor(options) {
  let stopAt;

  if (!options || !options.symbol || !typeof options.onSuccess === 'function') {
      throw new Error('Missing required options');
  }
  options.onTimeout = options.onTimeout || window.nx.ute.returnArg;
  options.timeout = options.timeout || 10;
  stopAt = (new Date()).getTime() + (options.timeout * 1000);
  new window.nx.ute.PeriodicalExecuter(function(pe) {
      if (typeof window[options.symbol] != "undefined") {
          options.onSuccess(options.symbol);
          pe.stop();
      }
      else if ((new Date()).getTime() > stopAt) {
          options.onTimeout(options.symbol);
          pe.stop();
      }
  }, 0.25);
}
export const getQueryParams = s => {
  const regex = /([^?#]*)(#.*)?$/;
  const query = s.match(regex);
  if (!query) return { };
  const params = query[0].split("&");
  const ret = {};
  for (let i=0;i<params.length;i++) {
    const pair = params[i].split("=");
    const key = decodeURIComponent(pair.shift());
    const vals = pair.length > 1 ? pair.join('=') : pair[0];
    if(ret[key]) {
      ret[key] = [...ret[key], ...vals];
    }else{
      ret[key] = vals;
    }
  }
  return(ret);
};
