/* global s, nx */
import { getElementByID, parseAjaxText } from '../ute/utils';
import { readDatalayer } from '../ute/utils';
/********************************
 * helpers
 *******************************/
const getEasySavings = () =>
  getElementByID('adt_container')?.querySelector('#easy_savings');

const containerDamaged = () => {
  const easySavings = getEasySavings();
  easySavings.parentNode.removeChild(easySavings);

  if (nx.dlg_add_to_cart) {
    nx.dlg_add_to_cart.setDimensions();
  }
};
const clearHeading = () => {
  let esi = document.querySelectorAll('.easy_savings_item, .easy-savings__items');
  if (esi.length === 0) {
    let esh = document.querySelector('.easy-added');
    if (esh) {
      esh.parentNode.parentNode.removeChild(esh.parentNode);
    } else {
      esh = document.querySelectorAll('.easy-savings > h3, .easy-savings > p');
      esh.forEach(h => { h.parentNode.removeChild(h) });
    }
  }
};
/********************************
 * swapOrderItem()
 *******************************/
const swapOrderItems = (
  orderItemID,
  decision,
  reason,
  qty,
  callback,
  errCallback,
  extras
) => {
  const elt = getElementByID(`soft_oi_${orderItemID}`);
  const swapPanel = getElementByID('added_to_cart');
  const needContainer = swapPanel ? false : true;
  const easySavings = getEasySavings();

  if (decision == 'accept') {
    fetch(
      `/main-ajax-swap?itemid=${orderItemID}&decision=${decision}&wrap_container=${needContainer}&quantity=${qty}&feature=${extras.feature.value}&feature_ident=${extras.feature_ident.value}`,
      {
        method: 'POST'
      }
    )
      .then(parseAjaxText)
      .then(txt => {
        document.body.style.cursor = 'default';
        if (easySavings) {
          // triggered from adding an item
          easySavings.parentNode.innerHTML =
            txt + easySavings.parentNode.innerHTML;
          containerDamaged();
        } else if (elt && !elt.closest('#dialog-content')) {
          // on bulk add page or Order History page
          elt.innerHTML = txt;
          clearHeading();
        } else {
          // triggered from cart
          if (callback) {
            callback(); //eslint-disable-line callback-return
          }
          s.linkTrackVars = 'eVar37,eVar85,events';
          s.linkTrackEvents = 'event13,event131';
          s.eVar37 = reason;
          s.eVar85 = 'cart';
          s.events = 'event13,event131';
          s.tl(true, 'o', 'Easy Alternative Reason');
        }

        if (nx.cart) {
            nx.cart.refreshCart();
        }
        // update the datalayer and then incartstrip
        document.getElementById('adt_container').querySelectorAll('script').forEach(scriptlet => {eval(scriptlet.innerHTML);}); // eslint-disable-line no-eval
        nx.addToCart.updateInCartStrip();
      })
      .catch(() => {
        if (errCallback) {
          errCallback();
        }
      });
  } else {
    fetch(
      `/main-ajax-swap?itemid=${orderItemID}&decision=${decision}&feature=${extras.feature.value}&feature_ident=${extras.feature_ident.value}`,
      {
        method: 'POST'
      }
    )
      .then(parseAjaxText)
      .then(txt => {
        elt.innerHTML = txt;
        // on complete
        document.body.style['cursor'] = 'default';
        let adtc = getElementByID('adt_container');
        if (adtc) {
          containerDamaged();
        } else {
          clearHeading();
        }
        if (
            [...document.querySelectorAll('#adt_container > div')].filter(e =>
              e.style.display != 'none'
            ).length == 0
          ) {
          // close lightbox if no recommended products or recommended products panel is invisible
          Dialogs.close();
        }

        // on success
        if (callback) {
          callback(); //eslint-disable-line callback-return
        }
      });
  }
  document.body.style.cursor = 'progress';
};

export default swapOrderItems;
