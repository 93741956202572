/* global $j */
import {
    Swipable, showSwipeIcon
} from './Swipable.js';
import {
    BasicCarousel
} from './BasicCarousel.js';

//PLP mini image carousels
var PlpImageCarousel = function PlpImageCarousel(wrapper) {
    this.wrapper = wrapper;
    this.images = [wrapper.querySelector('img')];
    if (this.images[0].clientWidth == 0) {
        return null;
    }
    this.BLANK = '/media/images/new/blank.gif';
    this.curPos = 0;
    this.inner = wrapper.querySelector('.plp_image_inner');
    this.imageURLs = wrapper.getAttribute('data-urls').split(',');
    this.numPanes = this.imageURLs.length;
    this.PANE_WIDTH = this.images[0].clientWidth;
    wrapper.style.width = this.PANE_WIDTH + 'px';
    //add the navigation
    this.dotsArr = [];
    this.dotsSelectedClass = 'plp_image_li-selected';
    this.dotsContainerClass = 'plp_image_ul';
    this.dotsClass = 'plp_image_li';
    this.navLeftClass = 'plp_image_left';
    this.navRightClass = 'plp_image_right';
    this.setupNav();
    //set up images
    this.setupImages();
    this.localStorageName = 'swipedOnPLP';
    this.adobeDescription = 'plp image carousel';
    this.swipeEvent = 'event90';
    this.clickEvent = 'event91';
    if (!wrapper.closest('.slidable')) { // don't put a swipable inside a product carousel
        new Swipable(
            this.slideNext.bind(this),
            this.slidePrev.bind(this),
            this.resetScroll.bind(this),
            this.animateScroll.bind(this),
            this.inner,
            30
        );
    }
    // if the page resizes, we need to redo the image carousel...
    if (wrapper.closest('.griditem--small, .griditem')) {
        window.addEventListener('resize', function (evt) {
            wrapper.plpImageCarousel.resize();
        });
    }
    return this;
};
PlpImageCarousel.prototype = new BasicCarousel();
PlpImageCarousel.prototype.constructor = PlpImageCarousel;
PlpImageCarousel.prototype.setupImages = function setupImages() {
    var i;
    var link = this.wrapper.querySelector('a');
    link.draggable = false;
    var newLink;
    var linkImg;
    for (i = 1; i < this.numPanes; i++) {
        newLink = link.cloneNode(true);
        linkImg = newLink.querySelector('img');
        this.images.push(linkImg);
        if (linkImg.loading === 'lazy') {
            linkImg.loading = 'auto'
        }
        linkImg.src = this.BLANK;
        this.inner.appendChild(newLink);
        this.inner.style.width = (this.inner.clientWidth + this.PANE_WIDTH) + 'px';
    }
};
PlpImageCarousel.prototype.resize = function resize() {
    this.PANE_WIDTH = this.images[0].clientWidth;
    this.wrapper.style.width = this.PANE_WIDTH + 'px';
    this.slideTo(0);
};
PlpImageCarousel.prototype.checkImg = function checkImg(num) {
    if (this.images[num].src.indexOf(this.BLANK)) {
        this.images[num].src = this.imageURLs[num];
    }
};

function setupPlpImageCarousels() {
    var plpImages = $j('.plp_image');
    if (plpImages.length == 0) return false;
    plpImages.each(function () {
        const wrapper = this;
        function loaded() {
            wrapper.plpImageCarousel = wrapper.plpImageCarousel || new PlpImageCarousel(wrapper);
        }
        var img = wrapper.querySelector('img');
        if (img.complete) {
            loaded();
        } else {
            img.addEventListener('load', loaded);
        }
    });
    if (!localStorage.getItem('swipedOnPLP')) {
        showSwipeIcon(plpImages[0], 'swipe_icon_plp');
    }

    return true;
}

export {PlpImageCarousel, setupPlpImageCarousels};
