var universalMenu = function universalMenu() {
  this.initialize.apply(this, arguments);
};

universalMenu.prototype.initialize = function initialize() {
  var obj = this;
  obj.initializeElements();
};

universalMenu.prototype.initializeElements = function initializeElements() {
  var ids = document.querySelectorAll('.u-menu-expand-static');
  var obj = this;
  var umenu, expand, loaders, l, lazyloaders, ll, ll_x;
  var menu_buttons, mb, menu_dropdown_id, mb_dropdown;

  for (let i = 0; i < ids.length; i++) {
    umenu = ids[i];
    expand = umenu.querySelectorAll('.u-menu-expand');
    for (let j = 0; j < expand.length; j++) {
      umenu.classList.remove('u-menu-expand-static');

      let umenuClickHandler = obj.expandToggle.bind(obj, umenu, expand[j]);
      umenu.registry ??= {};
      umenu.registry.click ??= [];
      umenu.registry.click.push(umenuClickHandler);
      umenu.addEventListener('click', umenuClickHandler);

      let expandClickHandler = obj.expandToggle.bind(obj, umenu, expand[j]);
      expand[j].registry ??= {};
      expand[j].registry.click ??= [];
      expand[j].registry.click.push(expandClickHandler);
      expand[j].addEventListener('click', expandClickHandler);
    }
  }

  loaders = document.querySelectorAll('.u-menu-loader');
  for (let i = 0; i < loaders.length; i++) {
    l = loaders[i];
    obj.menuLoader(l);
  }

  lazyloaders = document.querySelectorAll('.u-menu-lazy-loader');
  for (let i = 0; i < lazyloaders.length; i++) {
    ll = lazyloaders[i];
    ll_x = ll.querySelectorAll('.u-menu-expand');

    ll_x[0].registry ??= {};
    ll_x[0].registry.click ??= [];
    ll_x[0].registry.click.forEach(h =>
      ll_x[0].removeEventListener('click', h)
    );
    ll_x[0].registry.click = [];

    ll.registry ??= {};
    ll.registry.click ??= [];
    ll.registry.click.forEach(h => ll.removeEventListener('click', h));
    ll.registry.click = [];

    let ll_xClickHandler = obj.lazyExpand.bind(obj, ll, ll_x[0]);
    ll_x[0].registry.click.push(ll_xClickHandler);
    ll_x[0].addEventListener('click', ll_xClickHandler);

    let llClickHandler = obj.lazyExpand.bind(obj, ll, ll_x[0]);
    ll.registry.click.push(llClickHandler);
    ll.addEventListener('click', llClickHandler);
  }

  menu_buttons = document.querySelectorAll('.u-menu-button');
  for (let i = 0; i < menu_buttons.length; i++) {
    mb = menu_buttons[i];
    menu_dropdown_id = mb.getAttribute('data-target');
    mb_dropdown = document.getElementById(menu_dropdown_id);

    mb.registry ??= {};
    mb.registry.click ??= [];
    mb.registry.click.forEach(h => mb.removeEventListener('click', h));
    mb.registry.click = [];

    let mbClickHandler = obj.toggleMenu.bind(obj, mb, mb_dropdown);
    mb.registry.click.push(mbClickHandler);
    mb.addEventListener('click', mbClickHandler);
  }
};

universalMenu.prototype.menuLoader = function menuLoader(l) {
  var obj = this;
  l.innerHTML = '<img src="/media/images/new/ajax-mini.gif" />';

  fetch(l.getAttribute('data-url'), { method: 'GET' })
    .then(response => response.text())
    .then(data => {
      /* On success or failure, we don't want to retry the
       * loader. Either will cause a request loop. */
      l.innerHTML = data;
      l.classList.remove('u-menu-loader');
      l.classList.remove('u-menu-delay-loader');
      obj.initializeElements();
    });
};

universalMenu.prototype.toggleMenu = function toggleMenu(button, dropdown, ev) {
  var icon, dls;
  if (ev) {
    var element = ev.target;
    if (element.tagName == 'A') return;
    if (element.tagName == 'INPUT') return;
    ev.stopPropagation();
    ev.preventDefault();
  }

  icon = button.querySelectorAll('.fa');
  if (dropdown.classList.contains('menu-open')) {
    this.collapseMenu(dropdown, button);
  } else {
    dls = dropdown.querySelectorAll('.u-menu-delay-loader');
    for (let i = 0; i < dls.length; i++) {
      this.menuLoader(dls[i]);
    }
    this.expandMenu(dropdown, button);
  }
};

universalMenu.prototype.closeMenu = function closeMenu(button, dropdown, ev) {
  if (
    dropdown.classList.contains('menu-open') &&
    !dropdown.contains(ev.target)
  ) {
    this.collapseMenu(dropdown, button);
  }
};

universalMenu.prototype.collapseMenu = function collapseMenu(dropdown, button) {
  dropdown.classList.remove('menu-open');
  dropdown.classList.add('menu-closed');
  button.classList.remove('u-menu-button-active');
  if (
    button.closest('.masthead-navigation') &&
    !button.closest('.order-dashboard')
  )
    button.closest('.masthead-navigation').classList.remove('menu-open');
  document.body.removeEventListener('click', button.boundCloseFunction);
};

universalMenu.prototype.expandMenu = function expandMenu(dropdown, button) {
  dropdown.classList.remove('menu-closed');
  dropdown.classList.add('menu-open');
  button.classList.add('u-menu-button-active');
  button.boundCloseFunction = this.closeMenu.bind(this, button, dropdown);
  if (
    button.closest('.masthead-navigation') &&
    !button.closest('.order-dashboard')
  )
    button.closest('.masthead-navigation').classList.add('menu-open');

  document.body.registry ??= {};
  document.body.registry.click ??= [];
  let bodyClickHandler = button.boundCloseFunction;
  document.body.registry.click.push(bodyClickHandler);
  document.body.addEventListener('click', bodyClickHandler);
};

universalMenu.prototype.lazyExpand = function lazyExpand(umenu, expand, ev) {
  var element, contents, contents_id, obj;
  if (ev) {
    element = ev.target;
    if (element.tagName == 'A' && !element.classList.contains('u-menu-expand-href'))
      return;
    ev.stopPropagation();
    ev.preventDefault();
  }

  contents = umenu.querySelector('.u-menu-lazy-container');
  if (!contents) return;
  obj = this;

  expand.classList.remove('fa-plus');
  expand.classList.add('fa-spinner');

  fetch(umenu.getAttribute('data-url'), { method: 'GET' })
    .then(response => (response.status == '200' ? response.text() : ''))
    .then(data => {
      contents.innerHTML = data;
      umenu.classList.remove('u-menu-lazy-loader');
      contents.classList.remove('u-menu-lazy-container');

      obj.expandToggle(umenu, expand);
      expand.classList.remove('fa-spinner');

      // Poke at the element to get it to redraw - IE11 CSS3
      // bug workaround.
      expand.style.zoom = '1';

      umenu.registry ??= {};
      umenu.registry.click ??= [];
      umenu.registry.click.forEach(h => umenu.removeEventListener('click', h));
      umenu.registry.click = [];

      expand.registry ??= {};
      expand.registry.click ??= [];
      expand.registry.click.forEach(h =>
        expand.removeEventListener('click', h)
      );
      expand.registry.click = [];

      let umenuClickHandler = obj.expandToggle.bind(obj, umenu, expand);
      umenu.registry.click.push(umenuClickHandler);
      umenu.addEventListener('click', umenuClickHandler);

      let expandClickHandler = obj.expandToggle.bind(obj, umenu, expand);
      expand.registry.click.push(expandClickHandler);
      expand.addEventListener('click', expandClickHandler);
    });
};

universalMenu.prototype.expandToggle = function expandToggle(
  umenu,
  expand,
  ev
) {
  var element;
  var proceed;
  var isFolded = umenu.classList.contains('u-menu-folded');

  if (ev) {
    element = ev.target;
    //if someone has clicked on an open category header, then follow the link
    proceed = element.classList.contains('u-menu-expand-href') && isFolded;
    if (element.tagName == 'A' && !proceed) return;
    ev.stopPropagation();
    ev.preventDefault();

    if (!/u-menu/.test(ev.target.className)) {
      return; /* Allow selection to work */
    }
  }
  this.collapseAll(umenu);
  if (isFolded) {
    umenu.classList.remove('u-menu-folded');
    umenu.classList.add('u-menu-open');

    expand.classList.remove('fa-plus');
    expand.classList.add('fa-minus');
    expand.title = 'Collapse category';
  }
};

universalMenu.prototype.collapseAll = function collapseAll(clickedMenu) {
  // grab the containing structure
  clickedMenu = clickedMenu.parentElement.parentElement;
  if (!clickedMenu) {
    return;
  }
  //collapse all its sections
  clickedMenu.querySelectorAll('.u-menu-open').forEach(function (el) {
    el.classList.remove('u-menu-open');
    el.classList.add('u-menu-folded');
  });
  clickedMenu.querySelectorAll('.fa-minus').forEach(function (el) {
    el.classList.remove('fa-minus');
    el.classList.add('fa-plus');
    el.title = 'Expand category';
  });
};

export default universalMenu;
