import * as pc from './components/addToCart/PostcodePrompt.js';
import * as qc from './components/products/quantity_controls.js';
import { AddToCartForm, updateOnAdd } from './components/addToCart/AddToCartForm.js';
import { PriceLoader } from './components/prices/PriceLoader.js';
import { login_lightbox } from './components/login/login_lightbox.js';
import { loginSomewhere } from './components/login/loginSomewhere';
import {
  replace_svg,
  returnArg,
  watchFor,
  getQueryParams,
  s_addPageLoadEvent,
  doAdobeWithVars,
  doAdobeEvent,
  doAdobeLink,
  doAdobeEvent_sampled,
  makePost,
  updateParam,
  identify,
  addClassName,
  readDatalayer,
  removeClassName,
  selectAll,
  unselectAll,
  toggleClassName,
  trackCataloguePage
} from './components/ute/utils.js';
import updateInCartStrip from './components/addToCart/updateInCartStrip';
import updateCart from './components/addToCart/updateCart';
import CartItems from './components/addToCart/CartItems';
import { setCookie, getCookie } from './components/ute/cookie';
import { stockUpdate } from './components/products/helpers/stockUpdate';
import {
  toggleLayerByDom,
  toggleLayerByName
} from './components/ute/togglerLayer';
import {
  limitSubmitAttachActions,
  limitSubmitReset
} from './components/forms/helpers';
import { setPopUpDivVisible } from './components/products/helpers/setPopUpDivVisible';
import Tooltip from './components/tooltip/tooltip';
import { newsflash_lightbox, newsflash_reloadmessages, newsflash_read } from './components/news/newsflash';
import insertImage from './components/ute/insertImg';
import updateOrderTotal from './components/orders/updateOrderTotal';
import { dialogs, FormChecker } from './components/orders/dialogs';
import swapOrderItems from './components/orders/swapOrderItems';
import { editlinenote, uneditlinenote } from './components/orders/lineNote';
import {
  showOrderToast,
  mergeOrderDialog,
  mergeOrders,
  queueMergeOrderDialog
} from './components/orders/orderToasts';
import { enter_to_submit } from './components/orders/enterToSubmit';
import { displayEtdWarningOnPostcodeChange } from './components/orders/warningPostCode';
import { selectAllItems } from './components/orders/selectAllItems';
import { chooseEmailSub } from './components/orders/chooseEmailSub';
import { toggleFACheckbox } from './components/products/toggleFACheckbox';
import { wrapK2Message } from './components/ute/k2message';
import collectSearchHistory from './components/search/collectSearchHistory';
import searchClose from './components/search/searchClose';
import onPageLoad from './components/ute/onPageLoad';
import { cycleNotificationRibbons } from './components/notification/cycle';
import { viewCurrent } from './components/orders/viewCurrent.js';
import typeAheadNew from './components/search/typeAheadNew';
import universalMenu from './components/universalMenu';
import { select_cc_lightbox } from './components/selectCC';
import listsLoader from './components/listsLoader';
import { furnitureFreight, onlySubmitFurnitureFreight } from './components/products/furnitureFreight';
import { calendarToString, calendarParse } from './components/calendar/pikaday';
import PE from './components/ute/PeriodicalExecutor';
import { nextDialog } from './components/dialog/utils';
import startChat from './components/chat/startChat';
import { showFilters, applyFilters, expandFilter } from './components/products/filter';
import { increaseQty, decreaseQty, focusItem, changeItem } from './components/itemCard/itemCard';

window.nx ??= {};
window.nx.addToCart ??= {};
window.nx.calendar ??= {};
window.nx.dialogs ??= {};
window.nx.products ??= {};
window.nx.itemCard ??= {};
window.nx.orders ??= {};
window.nx.prices ??= {};
window.nx.login ??= {};
window.nx.forms ??= {};
window.nx.news ??= {};
window.nx.Tooltip ??= {};
window.nx.notification ??= {};
window.nx.search ??= {};
window.nx.universalMenu ??= {};
window.nx.selectCC ??= {};
window.nx.listsLoader ??= {};
window.nx.ute ??= {};
window.nx.chat ??= {};

// PC prompt for users to set postcode
window.nx.addToCart.DisplayPcPrompt = pc.DisplayPcPrompt;
window.nx.addToCart.pc_lightbox = pc.pc_lightbox;
window.nx.addToCart.change_postcode = pc.change_postcode;
window.nx.addToCart.PostcodePrompt = pc.PostcodePrompt;

//quantity controls for quantity fields
window.nx.products.add_quantity_controls = qc.add_quantity_controls;
window.nx.products.decrease_qty = qc.decrease_qty;
window.nx.products.increase_qty = qc.increase_qty;
window.nx.products.stockUpdate = stockUpdate;
window.nx.products.setPopUpDivVisible = setPopUpDivVisible;
window.nx.products.furnitureFreight = furnitureFreight;
window.nx.products.onlySubmitFurnitureFreight = onlySubmitFurnitureFreight;
window.nx.products.toggleFACheckbox = toggleFACheckbox;

// ajax add to cart
window.nx.addToCart.AddToCartForm = AddToCartForm;
window.nx.addToCart.updateOnAdd = updateOnAdd;
window.nx.addToCart.CartItems = CartItems;
window.nx.addToCart.dlg_add_to_cart = null;
window.nx.addToCart.updateInCartStrip = updateInCartStrip;
window.nx.addToCart.updateCart = updateCart;

// orders
window.nx.orders.updateOrderTotal = updateOrderTotal;
window.nx.orders.dialogs = dialogs;
window.nx.orders.FormChecker = FormChecker;
window.nx.orders.swapOrderItems = swapOrderItems;
window.nx.orders.viewCurrent = viewCurrent;
window.nx.orders.editlinenote = editlinenote;
window.nx.orders.uneditlinenote = uneditlinenote;
window.nx.orders.enter_to_submit = enter_to_submit;
window.nx.orders.displayEtdWarningOnPostcodeChange = displayEtdWarningOnPostcodeChange;
window.nx.orders.selectAllItems = selectAllItems;
window.nx.orders.chooseEmailSub = chooseEmailSub;
window.nx.orders.showOrderToast = showOrderToast;
window.nx.orders.queueMergeOrderDialog = queueMergeOrderDialog;
window.nx.orders.mergeOrderDialog = mergeOrderDialog;
window.nx.orders.mergeOrders = mergeOrders;

// itemcard
window.nx.itemCard.increaseQty = increaseQty;
window.nx.itemCard.decreaseQty = decreaseQty;
window.nx.itemCard.focusItem = focusItem;
window.nx.itemCard.changeItem = changeItem;

// chat
window.nx.chat.startChat = startChat;

// priceLoader
window.nx.prices.PriceLoader = PriceLoader;

// login
window.nx.login.login_lightbox = login_lightbox;
window.nx.login.loginSomewhere = loginSomewhere;

// forms
window.nx.forms.limitSubmitAttachActions = limitSubmitAttachActions;
window.nx.forms.limitSubmitReset = limitSubmitReset;

// pikaday calendar functions
window.nx.calendar.calendarToString = calendarToString;
window.nx.calendar.calendarParse = calendarParse;

// newsflash
window.nx.news.newsflash_lightbox = newsflash_lightbox;
window.nx.news.newsflash_reloadmessages = newsflash_reloadmessages;
window.nx.news.newsflash_read = newsflash_read;

// notification
window.nx.notification.cycleNotificationRibbons = cycleNotificationRibbons;

// Tooltip
window.nx.Tooltip = Tooltip;

// search
window.nx.search.collectSearchHistory = collectSearchHistory;
window.nx.search.searchClose = searchClose;
window.nx.search.typeAheadNew = typeAheadNew;

// universalMenu
window.nx.universalMenu = universalMenu;

// selectCC
window.nx.selectCC.select_cc_lightbox = select_cc_lightbox;

// listsLoader: my wish button
window.nx.listsLoader = listsLoader;


// furnitureFreight
window.nx.furnitureFreight = furnitureFreight;

// utils
window.nx.ute.replace_svg = replace_svg;
window.nx.ute.s_capturedInitialLoad = false;
window.nx.ute.s_addPageLoadEvent = s_addPageLoadEvent;
window.nx.ute.trackCataloguePage = trackCataloguePage;
window.nx.ute.getCookie = getCookie;
window.nx.ute.doAdobeWithVars = doAdobeWithVars;
window.nx.ute.doAdobeEvent = doAdobeEvent;
window.nx.ute.doAdobeLink = doAdobeLink;
window.nx.ute.doAdobeEvent_sampled = doAdobeEvent_sampled;
window.nx.ute.makePost = makePost;
window.nx.ute.updateParam = updateParam;
window.nx.ute.identify = identify;
window.nx.ute.readDatalayer = readDatalayer;
window.nx.ute.setCookie = setCookie;
window.nx.ute.addClassName = addClassName;
window.nx.ute.removeClassName = removeClassName;
window.nx.ute.toggleClassName = toggleClassName; // TODO: Manual test - @Kyle
window.nx.ute.toggleLayerByDom = toggleLayerByDom;
window.nx.ute.toggleLayerByName = toggleLayerByName;
window.nx.ute.insertImage = insertImage;
window.nx.ute.selectAll = selectAll;
window.nx.ute.unselectAll = unselectAll;
window.nx.ute.wrapK2Message = wrapK2Message;
window.nx.ute.onPageLoad = onPageLoad; // TODO: Manual test - @Kyle
window.nx.ute.PeriodicalExecuter = PE;
window.nx.ute.returnArg = returnArg;
window.nx.ute.watchFor = watchFor;
window.nx.ute.getQueryParams = getQueryParams;

// dialog queue
window.nx.dialogs.dialogQueue = {};
window.nx.dialogs.nextDialog = nextDialog;

// filters
window.nx.products.showFilters = showFilters;
window.nx.products.applyFilters = applyFilters;
window.nx.products.expandFilter = expandFilter;
